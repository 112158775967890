import { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import ProductImageList from './ProductList';
import ProductService from '../../../api/productService';
import apiConfigInstance from '../../../api/apiConfig';
import { ProductPortrait, ProductsPortraitResult } from '../../../models/product';
import CategoryService from '../../../api/categoryService';
import { Category } from '../../../models/category';
import { Button, CircularProgress, Divider, Paper, Stack, TextField, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox, FormGroup, Switch, Collapse, Box, useMediaQuery, Link } from '@mui/material';
import { ShopOutlined, SentimentDissatisfiedOutlined } from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useError } from '../../errors/ErrorContext';
import { useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';

const PAGINATION_OFFSET = 10

const ShopPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects if it's a mobile device

  const [categories, setCategories] = useState<Category[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
  const categoryService = new CategoryService(apiConfigInstance);
  const productService = new ProductService(apiConfigInstance);
  const [loadingMoreProducts, setLoadingMoreProducts] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [productDataState, setProductDataState] = useState<{ loading: boolean, data: ProductsPortraitResult }>({ loading: true, data: { products: [], next: false, pos: 0 } });
  const [searchTerm, setSearchTerm] = useState<string>(''); // State for search term
  const [showAvailableOnly, setShowAvailableOnly] = useState<boolean>(false); // State for filtering available products
  const [sortOrder, setSortOrder] = useState<string>(''); // State for sorting order
  const productLookUp = useRef<Map<string | number, ProductsPortraitResult>>(new Map<string | number, ProductsPortraitResult>());


  const { handleError } = useError();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const categoryId = params.get("categoryId");

    categoryService.getCategories().then((categories) => {
      setCategories(categories);
      setCategoriesLoading(false);

      const foundCategory = categories.find((cat) => String(cat.id) === categoryId);
      let currentCategory: Category | null = null;
      if (foundCategory) {
        currentCategory = foundCategory;
        setSelectedCategory(foundCategory);
      } else {
        if (categories.length > 0) {
          setSelectedCategory(categories[0]);
          currentCategory = categories[0];
        }
      }

      productService.getProductsPortraits(currentCategory ? currentCategory.id : undefined, 0, PAGINATION_OFFSET).then((products) => {
        if (currentCategory) {
          productLookUp.current.set(currentCategory.id, products);
        }
        setProductDataState({ loading: false, data: products });
      }).catch((error: AxiosError) => {
        handleError(error, false, "No se han podido cargar los productos.");
        setProductDataState({ loading: false, data: { products: [], next: false, pos: 0 } });
      });

    }).catch((error: AxiosError) => {
      handleError(error, false, "No se han podido cargar las categorías.");
      setCategoriesLoading(false);
      setProductDataState({ loading: false, data: { products: [], next: false, pos: 0 } });
    });
  }, []);


  const LoadMoreProductPortraits = () => {
    setLoadingMoreProducts(true); // Set loading to true before API call

    productService
      .getProductsPortraits(
        selectedCategory?.id!,
        productDataState.data.pos,
        productDataState.data.pos + PAGINATION_OFFSET
      )
      .then((products) => {
        const existingProducts = productLookUp.current.get(selectedCategory?.id!);
        const newProducts = products.products || [];

        productLookUp.current.set(selectedCategory?.id!, {
          products: [...(existingProducts?.products || []), ...newProducts],
          pos: products.pos,
          next: products.next
        });
        setProductDataState((prevValue) => ({
          ...prevValue,
          data: {
            ...prevValue.data,
            products: [...prevValue.data.products, ...products.products],
            pos: products.pos,
            next: products.next
          },

        }));
      })
      .catch((error) => {
        console.error("Failed to load more products:", error);
      })
      .finally(() => {
        setLoadingMoreProducts(false); // Reset loading state
      });
  };

  const setCategoryId = (newCategoryId: string) => {
    // Get the current query parameters (if any)
    const searchParams = new URLSearchParams(window.location.search);

    // Set or update the 'categoryId' parameter
    searchParams.set('categoryId', newCategoryId);

    // Use navigate to update the URL with the new query parameters
    navigate({
      pathname: window.location.pathname, // Keeps the current path
      search: `?${searchParams.toString()}`, // Sets the new query string
    });
  };

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory({ ...category });
    setCategoryId(category.id.toString())
    if (productLookUp.current.has(category.id)) {
      setProductDataState({ loading: false, data: productLookUp.current.get(category.id)! });
    } else {
      setProductDataState(prevData => ({ ...prevData, loading: true, data: prevData.data }));
      productService.getProductsPortraits(category.id, 0, PAGINATION_OFFSET).then((products) => {
        productLookUp.current.set(category.id, products);
        setProductDataState({ loading: false, data: products });
      }).catch((error: AxiosError) => {
        handleError(error, false, "No se han podido cargar los productos.");
        setProductDataState({ loading: false, data: { products: [], next: false, pos: 0 } });
      });
    }
  };

  const handleShowFilters = () => {
    setShowFilters((prevValue) => !prevValue);
  }

  // Filter and sort products
  const filteredProducts = productDataState.data.products
    .filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) && (!showAvailableOnly || !product.sold)
    )
    .sort((a, b) => {
      if (sortOrder === 'price-asc') return a.price - b.price;
      if (sortOrder === 'price-desc') return b.price - a.price;
      return 0;
    });

  return (
    <Paper sx={{ backgroundColor: 'rgba(255, 255, 255, 0.90)' }}>
      <Grid container display="flex" justifyContent="center" spacing={2}>
        {/* Left side: Categories */}
        <Grid item xs={12} container alignItems="center" justifyContent="center" spacing={1} sx={{ mb: 2 }}>
          <Grid item><ShopOutlined fontSize="large" color="primary"></ShopOutlined></Grid>
          <Grid item><Typography variant="h4" gutterBottom>Tienda</Typography></Grid>
          <Grid item xs={12} container alignItems="center" justifyContent="center">
            <Divider style={{ backgroundColor: '#ddd', width: '80%' }} />
          </Grid>
        </Grid>

        <Grid item xs={10} md={2}>
          <Typography variant="h5" fontWeight={500} gutterBottom>Categorías</Typography>

          {categoriesLoading ? (
            <Stack sx={{ textAlign: 'center', alignItems: "center", mt: 2 }}>
              <CircularProgress />
              <Typography variant="body2" sx={{ mt: 2 }}>Cargando categorías...</Typography>
            </Stack>
          ) : categories.length === 0 ? (
            <Stack sx={{ textAlign: 'center', alignItems: "center", mt: 2 }}>
              <SentimentDissatisfiedOutlined fontSize="inherit" style={{ fontSize: "2rem", color: theme.palette.warning.main }} />
              <Typography variant="body2" sx={{ mb: 2 }}>No se han encontrado categorías</Typography>
            </Stack>
          ) : (
            <List>
              {categories.map((category, idx) => (
                <ListItemButton
                  disabled={productDataState.loading}
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  sx={{
                    bgcolor: selectedCategory?.id === category.id ? theme.palette.grey[400] : 'transparent',
                    color: selectedCategory?.id === category.id ? theme.palette.primary.contrastText : 'inherit',
                    '&:hover': {
                      bgcolor: theme.palette.grey[300],
                    },
                  }}
                >
                  <ListItemText primary={category.name} />
                </ListItemButton>
              ))}
            </List>
          )}
        </Grid>

        {/* Right side: Products */}
        <Grid item xs={11} md={8} sx={{ pt: 2, pb: 4 }}>
          <Stack direction={"row"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              gutterBottom
              textAlign={"center"}
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                borderRadius: 2,
                p: 2
              }}
            >
              {selectedCategory ? selectedCategory.name : "Productos"}
            </Typography>
            <FormGroup>
              <FormControlLabel control={<Switch checked={showFilters} defaultChecked onChange={() => handleShowFilters()} />} label="Mostrar Filtros" />
            </FormGroup>
          </Stack>

          <Divider sx={{ mb: 2 }}></Divider>
          {
            <Collapse in={showFilters} timeout={300} unmountOnExit>
              <Grid container spacing={2} alignItems={"center"} sx={{ my: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Buscar producto"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Grid >
                <Grid item xs={12} md={4}>
                  {/* Sorting options */}
                  <FormControl fullWidth>
                    <InputLabel id="sort-label">Ordenar por</InputLabel>
                    <Select
                      labelId="sort-label"
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}
                      label="Ordenar por"
                    >
                      <MenuItem value="price-asc">Precio Ascendente</MenuItem>
                      <MenuItem value="price-desc">Precio Descendente</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  {/* Checkbox to show available (not sold) products only */}
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showAvailableOnly}
                          onChange={(e) => setShowAvailableOnly(e.target.checked)}
                        />
                      }
                      label="Disponible"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Collapse>
          }

          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {productDataState.loading ? (
                <Stack sx={{ textAlign: 'center', alignItems: "center", mt: 2 }}>
                  <CircularProgress />
                  <Typography variant="body2" sx={{ mt: 2 }}>Cargando productos...</Typography>
                </Stack>
              ) : filteredProducts.length === 0 ? (
                <Stack sx={{ textAlign: 'center', alignItems: "center" }}>
                  <SentimentDissatisfiedOutlined fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>No se han encontrado productos</Typography>
                  <Button variant="outlined" sx={{ backgroundColor: "white", opacity: 0.9 }} onClick={() => navigate("/")}>
                    <Typography>Volver a la pantalla de inicio</Typography>
                  </Button>
                </Stack>
              ) : (
                <Stack>
                  <ProductImageList products={filteredProducts} mobileCols={2} fullWidthCols={3} />
                  {productDataState.data.next == true &&
                    <Box sx={{ width: "100%", alignItems: "center" }}>
                      <Button variant="outlined" disabled={loadingMoreProducts} onClick={() => LoadMoreProductPortraits()} sx={{ backgroundColor: "rgb(255,255,255,0.9)", width: isMobile ? 200 : 400 }}>{loadingMoreProducts ? <CircularProgress /> : "Cargar más Productos"}</Button>
                    </Box>
                  }


                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sx={{ mt: 6, mb: 2 }}>
              <Box
                sx={{
                  border: `2px solid ${theme.palette.primary.light}`, // Primary color border
                  borderRadius: "8px",         // Rounded corners
                  pt: "16px",             // Add padding around the content
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Optional shadow for emphasis
                  width: "100%",               // Full width
                }}
              >
                <Stack alignItems={"center"} spacing={1}>
                  <Typography textAlign={"center"} variant='body2'>
                    Si tienes alguna idea o ves algún producto que te guste pero está agotado, contáctame por Instagram y empezamos a crear 😊
                  </Typography>
                  <Link href="https://www.instagram.com/nayadelife" underline="none" target="_blank">
                    <InstagramIcon color="primary" fontSize='large' />
                  </Link>
                </Stack>
              </Box>

            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </Paper>
  );
}

export default ShopPage;
